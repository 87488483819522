var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.dialogAddEmail.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.email.buttonAdd)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.email.searchText},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"sort-desc":_vm.desc,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:sortDesc":function($event){_vm.desc=$event},"update:sort-desc":function($event){_vm.desc=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.picName",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.picName))])]}},{key:"item.createdBy",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.createdBy))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.dateFormat(items.item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"red"},on:{"click":[function($event){return _vm.deleteEmail(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-trash-can ")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus")])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.email.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('AddEmail',{ref:"dialogAddEmail",on:{"fetchEmail":_vm.fetchEmail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }