<template>
  <v-container fluid>
    <v-row
      justify="space-between"
    >
      <v-col>
        <v-btn
          v-if="userAccess.canCreate"
          color="primary"
          @click="$refs.dialogAddEmail.dialog = true"
        >
          {{$_strings.email.buttonAdd}}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.email.searchText"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="items"
          item-key="id"
          :server-items-length="totalItems"
          class="body"
          :sort-desc.sync="desc"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}">
          <template v-slot:[`item.picName`]=items>
            <span>{{ items.item.picName }}</span>
          </template>
          <template v-slot:[`item.createdBy`]=items>
            <span>{{ items.item.createdBy }}</span>
            <br/>
            <span>{{ dateFormat(items.item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.actions`]={item}>
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="edit(item)" >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="userAccess.canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon color="red" @click="deleteEmail(item)" @click.stop.prevent>
                  <v-icon> mdi-trash-can </v-icon>
                </v-btn>
              </template>
              <span>Hapus</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.email.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AddEmail
      @fetchEmail="fetchEmail"
      ref="dialogAddEmail"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import AddEmail from './Dialog/AddEmail.vue';

export default {
  picName: 'email',
  components: {
    AddEmail,
  },
  data() {
    return {
      isLoading: false,
      dialogAddEmail: false,
      desc: true,
      items: [],
      searchText: this.$route.query.search || '',
      headers: [
        {
          text: this.$_strings.email.nameLabel,
          value: 'picName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.email.emailLabel,
          value: 'email',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.email.createdBy,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchEmail();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    edit(item) {
      this.$refs.dialogAddEmail.dialog = true;
      if (item) {
        this.$refs.dialogAddEmail.emailId = item.id;
        this.$refs.dialogAddEmail.form.picName = item.picName;
        this.$refs.dialogAddEmail.form.email = item.email;
      }
    },
    search() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchEmail();
      }
    },
    fetchEmail() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ sortBy, sortDesc }),
        email: '',
      };
      if (this.searchText) filters.email = `qLike(${this.searchText})`;
      this.isLoading = true;
      this.$_services.email.getListEmail(this.skipEmptyStringObject(filters))
        .then((result) => {
          this.items = result.data.contents;
          this.totalItems = result.data.totalData;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    deleteEmail(item) {
      this.$dialog.warning({
        text: this.$_strings.email.deleteEmailConfirmMsg,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((res) => {
        if (!res) return;
        this.$root.$loading.show();
        this.$_services.email.deleteEmail(item.id)
          .then(() => {
            this.$dialog.notify.success(`Berhasil menghapus email "${item.email}"`);
            this.items.splice(this.items.indexOf(item), 1);
          }).finally(() => {
            this.$root.$loading.hide();
          });
      });
    },
  },
};
</script>
